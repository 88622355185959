/* stylelint-disable */
:global {
  .mol-ads-cmp {
    font-size: 13px;
  }

  .mol-ads-cmp div,
  .mol-ads-cmp span,
  .mol-ads-cmp h1,
  .mol-ads-cmp h2,
  .mol-ads-cmp h3,
  .mol-ads-cmp h4,
  .mol-ads-cmp h5,
  .mol-ads-cmp h6,
  .mol-ads-cmp p,
  .mol-ads-cmp blockquote,
  .mol-ads-cmp pre,
  .mol-ads-cmp a,
  .mol-ads-cmp abbr,
  .mol-ads-cmp acronym,
  .mol-ads-cmp address,
  .mol-ads-cmp big,
  .mol-ads-cmp cite,
  .mol-ads-cmp code,
  .mol-ads-cmp del,
  .mol-ads-cmp dfn,
  .mol-ads-cmp em,
  .mol-ads-cmp img,
  .mol-ads-cmp ins,
  .mol-ads-cmp kbd,
  .mol-ads-cmp q,
  .mol-ads-cmp s,
  .mol-ads-cmp samp,
  .mol-ads-cmp small,
  .mol-ads-cmp strike,
  .mol-ads-cmp strong,
  .mol-ads-cmp sub,
  .mol-ads-cmp sup,
  .mol-ads-cmp tt,
  .mol-ads-cmp var,
  .mol-ads-cmp b,
  .mol-ads-cmp u,
  .mol-ads-cmp i,
  .mol-ads-cmp center,
  .mol-ads-cmp dl,
  .mol-ads-cmp dt,
  .mol-ads-cmp dd,
  .mol-ads-cmp ol,
  .mol-ads-cmp ul,
  .mol-ads-cmp li,
  .mol-ads-cmp fieldset,
  .mol-ads-cmp form,
  .mol-ads-cmp label,
  .mol-ads-cmp legend,
  .mol-ads-cmp table,
  .mol-ads-cmp caption,
  .mol-ads-cmp tbody,
  .mol-ads-cmp tfoot,
  .mol-ads-cmp thead,
  .mol-ads-cmp tr,
  .mol-ads-cmp th,
  .mol-ads-cmp td,
  .mol-ads-cmp article,
  .mol-ads-cmp aside,
  .mol-ads-cmp canvas,
  .mol-ads-cmp details,
  .mol-ads-cmp embed,
  .mol-ads-cmp figure,
  .mol-ads-cmp figcaption,
  .mol-ads-cmp footer,
  .mol-ads-cmp header,
  .mol-ads-cmp hgroup,
  .mol-ads-cmp menu,
  .mol-ads-cmp nav,
  .mol-ads-cmp output,
  .mol-ads-cmp ruby,
  .mol-ads-cmp section,
  .mol-ads-cmp summary,
  .mol-ads-cmp time,
  .mol-ads-cmp mark,
  .mol-ads-cmp audio,
  .mol-ads-cmp video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */

  .mol-ads-cmp article,
  .mol-ads-cmp aside,
  .mol-ads-cmp details,
  .mol-ads-cmp figcaption,
  .mol-ads-cmp figure,
  .mol-ads-cmp footer,
  .mol-ads-cmp header,
  .mol-ads-cmp hgroup,
  .mol-ads-cmp menu,
  .mol-ads-cmp nav,
  .mol-ads-cmp section {
    display: block;
  }

  .mol-ads-cmp body {
    line-height: 1;
  }

  .mol-ads-cmp ol,
  .mol-ads-cmp ul {
    list-style: none;
  }

  .mol-ads-cmp blockquote,
  .mol-ads-cmp q {
    quotes: none;
  }

  .mol-ads-cmp blockquote::before,
  .mol-ads-cmp blockquote::after,
  .mol-ads-cmp q::before,
  .mol-ads-cmp q::after {
    content: '';
    content: none;
  }

  .mol-ads-cmp table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .mol-ads-cmp i {
    font-style: normal;
  }

  .mol-ads-cmp h1,
  .mol-ads-cmp h2,
  .mol-ads-cmp h3,
  .mol-ads-cmp h4 {
    font-weight: bold;
  }

  .mol-ads-cmp {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 99999;
    animation-iteration-count: 1;
    animation-duration: 0.75s;
    transform: translate3d(0, 0, 0);
    --shake-magnitude: 16px;
    tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  .mol-ads-cmp .mol-ads-cmp--banner {
    display: none;
    background-color: #fff;
    position: fixed;
    right: -32px; /* for IE. calc from below */
    bottom: -32px; /* - " - */
    left: -32px; /* - " - */
    right: calc(0px - var(--shake-magnitude));
    bottom: calc(0px - 2 * var(--shake-magnitude));
    left: calc(0px - var(--shake-magnitude));
    padding: -32px;
    padding: calc(1em + var(--shake-magnitude));
    padding-top: 0;
  }

  @media (min-width: 600px) {
    .mol-ads-cmp .mol-ads-cmp--banner {
      padding-bottom: 1em;
    }
  }

  .mol-ads-cmp--open {
    padding-bottom: 15em;
  }

  /* Enable scrolling but not clicks on channel pages */

  html.mol-ads-cmp--open #mobile-content .scrollable-content {
    height: auto;
    overflow-y: auto;
  }

  /* Disable scrolling on article pages only */

  html.mol-ads-cmp--open.article-page:not(.ccpa),
  html.mol-ads-cmp--open.article-page:not(.ccpa) body,
  html.mol-ads-cmp--open.article-page:not(.ccpa) #mobile-content .scrollable-content {
    overflow-y: hidden !important;
  }

  /* --> Prevent the article section from scrolling half-page down in certain conditions (click on some tooltips, etc) */

  html.mol-ads-cmp--open.article-page #mobile-content .scrollable-content {
    height: 90vh !important;
    max-height: 90vh !important;
  }

  html.mol-ads-cmp--open.article-page #mobile-content {
    position: fixed;
  }

  /* Keep the sharing bar in place on scrolling-prevented article pages */

  html.mol-ads-cmp--open.article-page #mobile-content header.mobile-sharing {
    top: 80px;
  }

  /* <-- End */

  .mol-ads-cmp .mol-ads-cmp--banner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    z-index: 99999;
  }

  .mol-ads-cmp .mol-ads-cmp--container {
    margin: 0 auto 20px;
    width: 95%;
  }

  .mol-ads-cmp .mol-ads-cmp--banner ul.mol-ads-cmp--publisher-purposes {
    list-style-type: disc;
    padding-left: 1.25em;
  }

  .mol-ads-cmp ul.mol-ads-cmp--publisher-purposes li {
    font-size: 1.25em;
    list-style-type: disc;
    margin: 0.65em 0;
  }

  .mol-ads-cmp .mol-ads-cmp--button-privacy-link-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    height: 80px;
  }

  .mol-ads-cmp .mol-ads-cmp--open-settings,
  .mol-ads-cmp .mol-ads-cmp--banner .mol-ads-cmp--btn-primary {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .mol-ads-cmp .mol-ads-cmp--banner .mol-ads-cmp--btn-primary {
    /* width: 14em;
    margin-top: 1em;
    margin-bottom: 1em; */
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    width: 200px;
    height: 60px;
    background-color: #0cac0d;
    border-radius: 2px;
  }

  .mol-ads-cmp .mol-ads-cmp--banner .mol-ads-cmp--btn-primary:hover {
    background-color: #0a9b0b;
    cursor: pointer;
  }

  @media (min-width: 600px) {
    .mol-ads-cmp .mol-ads-cmp--privacy-link-container {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      font-size: 0.9em;
    }

    .mol-ads-cmp .mol-ads-cmp--banner .mol-ads-cmp--button-privacy-link-group .mol-ads-cmp--btn-primary:hover {
      background-color: #0a9b0b;
    }
  }

  .mol-ads-cmp .mol-ads-cmp--open-settings {
    display: inline;
    text-align: left;
  }

  .mol-ads-cmp a.mol-ads-cmp--open-settings {
    text-decoration: underline;
  }

  .mol-ads-cmp--cookie-settings {
    float: left;
    cursor: pointer;
    display: block;
    border-radius: 3px;
    padding: 0.5em 0;
    width: 200px;
    margin-right: 20px;
    text-align: center;
    font-size: 16px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2902);
    color: #fff;
    height: 60px;
  }

  .mol-ads-cmp--cookie-settings:hover {
    background: rgba(0, 0, 0, 0.10196);
  }

  div.mol-ads-cmp--message,
  div.mol-ads-ccpa--message {
    font-size: 16px;
    width: 420px;
    height: 40px;
  }

  div.mol-ads-ccpa--message {
    margin-top: 35px;
  }

  .mol-ads-cmp .mol-ads-cmp--banner p {
    font-size: 1.4em;
  }

  .mol-ads-cmp--nobr {
    white-space: pre;
  }

  .mol-ads-cmp .mol-ads-cmp--tooltip {
    position: relative;
    display: inline-block;
  }

  .mol-ads-cmp--tooltip-trigger {
    position: relative;
    display: inline-block;
  }

  /* Prevent flickering on small screens when the tooltip
    appears just on top of the trigger and disappears, and reappears.
    (hack) We achieve this by lifting the trigger element above the
    tooltip, so the hover rules on it can take over and keep it open
  */

  .mol-ads-cmp--tooltip-trigger:hover {
    z-index: 9999999999;
  }

  /* The Icon */

  .mol-ads-cmp .mol-ads-cmp--info {
    width: 0.8em;
    height: 0.8em;
    margin-top: -0.2em;
    vertical-align: middle;
  }

  /* Alignment div to hold the tooltip in the right position */

  .mol-ads-cmp--tooltipcontent {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-width: 1em;
    border-style: solid;
    border-color: rgba(244, 249, 255, 1) transparent transparent transparent;
    pointer-events: none;
    z-index: 1;
  }

  /* The visible container of the tooltip */

  .mol-ads-cmp--tooltipframe {
    position: -webkit-sticky;
    position: sticky;
    left: 1em;
    right: 1em;
    margin: 1em !important;
    padding: 0.5em !important;
    overflow-y: auto;
    min-height: auto;
    display: block;
    border: 1px solid rgba(153, 153, 153, 1) !important;
    background-color: rgba(244, 249, 255, 1);
    max-width: calc(100vw - 2em);
    font-weight: normal;
    box-sizing: border-box;
    color: black;
    pointer-events: all;
  }

  @media (min-height: 600px) {
    .mol-ads-cmp--tooltipcontent {
      position: absolute;
      left: -100vw;
      right: -100vw;
      bottom: 15px;
    }

    .mol-ads-cmp--tooltipframe {
      overflow-y: visible;
    }
  }

  .mol-ads-cmp .mol-ads-cmp--tooltipcontent:target {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  @media (min-height: 600px) {
    /* Triangles */
    .mol-ads-cmp .mol-ads-cmp--tooltipcontent:target::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -16px;
      margin-left: -0.6em;
      border-width: 1em 0.6em;
      border-style: solid;
      border-color: rgba(153, 153, 153, 1) transparent transparent transparent;
    }

    .mol-ads-cmp .mol-ads-cmp--tooltipcontent:target::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -14px;
      margin-left: -0.6em;
      border-width: 1em 0.6em;
      border-style: solid;
      border-color: rgba(244, 249, 255, 1) transparent transparent transparent;
      z-index: 2;
    }
  }

  /* Tooltip close button */

  .mol-ads-cmp--tooltip-close {
    float: right;
    display: block;
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg xmlns='http://www.w3.org/2000/svg' width='27' height='22' viewBox='-4.89 0 52.77 43' id='icon--closeSmlFat' x='318' y='264'%3e%3ctitle%3eclose_sml_fat%3c/title%3e%3cpath d='M21.5 13.032L9.06.592A1.999 1.999 0 0 0 6.224.59L.591 6.224a2.008 2.008 0 0 0 0 2.836l12.44 12.44L.592 33.94a1.999 1.999 0 0 0 0 2.836l5.633 5.633a2.008 2.008 0 0 0 2.836 0l12.44-12.44 12.44 12.44a2 2 0 0 0 2.836 0l5.633-5.633a2.008 2.008 0 0 0 0-2.836L29.968 21.5l12.44-12.44a2 2 0 0 0 0-2.836L36.776.591a2.007 2.007 0 0 0-2.836 0L21.5 13.032z' fill='%23CCC' fill-rule='evenodd'/%3e%3c/svg%3e");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
  }

  /* Hide the tooltip close button on mouse-triggered scenarios */

  .mol-ads-cmp--tooltip-trigger:focus:not(hover) ~ .mol-ads-cmp--tooltipcontent .mol-ads-cmp--tooltip-close,
  .mol-ads-cmp--tooltip-trigger:active:not(hover) ~ .mol-ads-cmp--tooltipcontent .mol-ads-cmp--tooltip-close {
    display: none;
  }

  .mol-ads-cmp .mol-ads-cmp--tooltip span.mol-ads-cmp--tooltipcontent-header {
    margin-right: 0.4em;
    font-weight: bold;
    white-space: normal;
  }

  .mol-ads-cmp ul.mol-ads-cmp--publisher-purposes .mol-ads-cmp--tooltip li {
    font-size: 0.875em;
    list-style-type: none;
    margin: 0.65em 0;
  }

  @keyframes :global(shake) {
    0% {
      transform: translate3d(var(--shake-magnitude), 0, 0);
    }
    10% {
      transform: translate3d(calc(var(--shake-magnitude) * -0.625), 0, 0);
    }
    20% {
      transform: translate3d(calc(var(--shake-magnitude) * 0.5), 0, 0);
    }
    30% {
      transform: translate3d(calc(var(--shake-magnitude) * -0.25), 0, 0);
    }
    40% {
      transform: translate3d(calc(var(--shake-magnitude) * 0.125), 0, 0);
    }
    50% {
      transform: translate3d(calc(var(--shake-magnitude) * -0.0625), 0, 0);
    }
    60% {
      transform: translate3d(0px, 0, 0);
    }
  }

  @keyframes :global(shake2) {
    0% {
      transform: translate3d(calc(var(--shake-magnitude) * 1), 0, 0);
    }
    33% {
      transform: translate3d(calc(var(--shake-magnitude) * -0.5), 0, 0);
    }
    66% {
      transform: translate3d(calc(var(--shake-magnitude) * 0.125), 0, 0);
    }
    100% {
      transform: translate3d(0px, 0, 0);
    }
  }

  @keyframes :global(bounce) {
    0% {
      transform: translate3d(0, var(--shake-magnitude), 0);
    }
    10% {
      transform: translate3d(0, calc(var(--shake-magnitude) * -0.625), 0);
    }
    20% {
      transform: translate3d(0, calc(var(--shake-magnitude) * 0.5), 0);
    }
    30% {
      transform: translate3d(0, calc(var(--shake-magnitude) * -0.25), 0);
    }
    40% {
      transform: translate3d(0, calc(var(--shake-magnitude) * 0.125), 0);
    }
    50% {
      transform: translate3d(0, calc(var(--shake-magnitude) * -0.0625), 0);
    }
    60% {
      transform: translate3d(0, 0px, 0);
    }
  }

  @keyframes :global(bounce2) {
    0% {
      transform: translate3d(0, var(--shake-magnitude), 0);
    }
    15% {
      transform: translate3d(0, 0px, 0);
    }
    30% {
      transform: translate3d(0, calc(var(--shake-magnitude) * 0.5), 0);
    }
    45% {
      transform: translate3d(0, 0px, 0);
    }
    60% {
      transform: translate3d(0, calc(var(--shake-magnitude) * 0.25), 0);
    }
    75% {
      transform: translate3d(0, 0px, 0);
    }
    90% {
      transform: translate3d(0, calc(var(--shake-magnitude) * 0.125), 0);
    }
    100% {
      transform: translate3d(0, 0px, 0);
    }
  }

  @keyframes :global(bounce3) {
    0% {
      transform: translate3d(0, calc(var(--shake-magnitude) * -1), 0);
    }
    20% {
      transform: translate3d(0, 0px, 0);
    }
    40% {
      transform: translate3d(0, calc(var(--shake-magnitude) * -0.5), 0);
    }
    60% {
      transform: translate3d(0, 0px, 0);
    }
    80% {
      transform: translate3d(0, calc(var(--shake-magnitude) * -0.25), 0);
    }
    100% {
      transform: translate3d(0, 0px, 0);
    }
  }

  @keyframes :global(bounce4) {
    0% {
      transform: translate3d(0, calc(var(--shake-magnitude) * 1), 0);
    }
    33% {
      transform: translate3d(0, calc(var(--shake-magnitude) * -0.5), 0);
    }
    66% {
      transform: translate3d(0, calc(var(--shake-magnitude) * 0.125), 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes :global(bounce4r) {
    0% {
      transform: translate3d(0, calc(var(--shake-magnitude) * -1), 0);
    }
    33% {
      transform: translate3d(0, calc(var(--shake-magnitude) * 0.5), 0);
    }
    66% {
      transform: translate3d(0, calc(var(--shake-magnitude) * -0.125), 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes :global(bounce3d) {
    0% {
      transform: translate3d(0, 0, var(--shake-magnitude));
    }
    20% {
      transform: translate3d(0, 0px, 0);
    }
    40% {
      transform: translate3d(0, 0, calc(var(--shake-magnitude) * -0.5));
    }
    60% {
      transform: translate3d(0, 0px, 0);
    }
    80% {
      transform: translate3d(0, 0, calc(var(--shake-magnitude) * -0.25));
    }
    100% {
      transform: translate3d(0, 0px, 0);
    }
  }

  .mol-ads-cmp .mol-ads-cmp--collapse {
    margin-bottom: 1em;
  }

  .mol-ads-cmp .mol-ads-cmp--collapse-trigger::before {
    content: 'âŒƒ';
    display: block;
    float: right;
  }

  .mol-ads-cmp .mol-ads-cmp--collapsed .mol-ads-cmp--collapse-trigger::before {
    content: 'âŒ„';
    font-size: 2em;
  }

  .mol-ads-cmp .mol-ads-cmp--collapse-target,
  .mol-ads-cmp .mol-ads-cmp--collapse-trigger {
    background-color: #eee;
    color: #000;
    display: block;
    font-size: 1.4em;
    line-height: 1.4em;
    margin-bottom: 0.1em;
    padding: 0.5em 1em;
  }

  :root {
    --mol-ads-cmp--border: solid 1px #e3e3e3;
    --mol-ads-cmp--height: 3em;
    --mol-ads-cmp--primary: #0cac0d;
    --mol-ads-cmp--secondary: transparent;
  }

  .mol-ads-cmp p,
  .mol-ads-cmp li {
    font-size: 0.875em;
  }

  .mol-ads-cmp h2 {
    font-size: 1.25em;
    margin: 1.5em 0 0.9em;
  }

  .mol-ads-cmp h3,
  .mol-ads-cmp h4 {
    font-size: 1.6em;
    margin-bottom: 0.5em;
    margin-top: 1.5em;
  }

  .mol-ads-cmp p {
    line-height: 1.4em;
    margin-bottom: 0.5em;
  }

  .mol-ads-cmp .mol-ads-cmp--btn-primary,
  .mol-ads-cmp .mol-ads-cmp--btn-secondary {
    border-radius: 3px;
    margin-bottom: 1.25em;
    padding: 0.5em 0;
    width: 14em;
  }

  .mol-ads-cmp .mol-ads-cmp--btn-primary {
    background: #0cac0d;
    background: var(--mol-ads-cmp--primary);
    border: none;
    color: #fff;
  }

  .mol-ads-cmp .mol-ads-cmp--btn-secondary {
    background: transparent;
    background: var(--mol-ads-cmp--secondary);
    border: solid 1px #e3e3e3;
    border: var(--mol-ads-cmp--border);
    color: #004db3;
  }

  .mol-ads-cmp {
    display: none;
  }

  .ccpa .mol-ads-cmp {
    pointer-events: none;
  }

  .ccpa .mol-ads-cmp--banner {
    pointer-events: all;
  }

  .mol-ads-cmp--open .mol-ads-cmp {
    display: block;
  }

  .mol-ads-cmp .mol-ads-cmp--settings {
    display: none;
  }

  .mol-ads-cmp.mol-ads-cmp--settings-open,
  .mol-ads-cmp.mol-ads-cmp--settings-open .mol-ads-cmp--settings {
    display: block;
  }

  .mol-ads-cmp.mol-ads-cmp--settings-open .mol-ads-cmp--banner {
    display: none;
  }

  .mol-ads-cmp--open .mol-ads-cmp--banner {
    display: block;
  }

  .mol-ads-cmp--restricted {
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
  }

  .mol-ads-cmp .mol-ads-cmp--collapsed .mol-ads-cmp--collapse-target {
    display: none;
  }

  .mol-ads-cmp .mol-ads-cmp--banner-wrapper {
    display: none;
  }

  .mol-ads-cmp--open .mol-ads-cmp .mol-ads-cmp--banner-wrapper {
    display: -ms-flexbox;
    display: flex;
  }

  .ccpa .mol-ads-cmp .mol-ads-cmp--modal-dialog {
    pointer-events: all;
  }

  .mol-ads-cmp .mol-ads-cmp--modal {
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
  }

  .mol-ads-cmp .mol-ads-cmp--modal-dialog {
    background-color: #fff;
    border: solid 10px #e3e3e3;
    height: 100%;
    margin: 0 auto;
    position: relative;
    top: 0;
    width: 100%;
  }

  .mol-ads-cmp .mol-ads-cmp--modal-content {
    margin: 0 auto;
    width: 95%;
  }

  .mol-ads-cmp .mol-ads-cmp--modal-footer {
    margin-bottom: 1em;
    text-align: center;
    height: 50px;
  }

  .mol-ads-cmp .mol-ads-cmp--settings h3,
  .mol-ads-cmp .mol-ads-cmp--settings h4 {
    font-size: 1em;
    font-weight: bold;
  }

  .mol-ads-cmp .mol-ads-cmp--settings h5 {
    font-size: 0.9rem;
    font-weight: bold;
    margin: 1rem 0 0.4rem 0;
  }

  .mol-ads-cmp .mol-ads-vendors-paragraph {
    margin-top: 10px;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-purposes,
  .mol-ads-cmp .mol-ads-cmp--settings-consents {
    list-style-type: none;
    margin-top: 7px;
    padding-left: 0;
    border-bottom: solid 1px #e3e3e3;
  }

  .mol-ads-cmp .mol-ads-cmp--tab-body a {
    color: #004db3;
  }

  .mol-ads-cmp .mol-ads-cmp--tab-body a:hover {
    color: #003780;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-purposes-title {
    font-size: 14px;
    font-weight: bold;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-consents li {
    font-size: 14px;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-purposes {
    counter-reset: mol-ads-cmp--settings-purposes;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-purpose {
    margin-bottom: 0.7em;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-purpose::before {
    background-color: #00aad2;
    border-radius: 50%;
    color: #fff;
    content: counter(mol-ads-cmp--settings-purposes);
    counter-increment: mol-ads-cmp--settings-purposes;
    display: inline-block;
    font-size: 10px;
    line-height: 15px;
    margin-right: 0.5em;
    text-align: center;
    width: 14px;
    height: 14px;
    vertical-align: 2px;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-consent {
    background-color: #fff;
    border-top: solid 1px #e3e3e3;
    border-top: var(--mol-ads-cmp--border);
  }

  .mol-ads-cmp .mol-ads-tcf-vendors {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 17px;
    font-size: 14px;
  }

  .mol-ads-cmp .mol-ads-non-tcf-vendors {
    list-style-type: none;
  }

  .mol-ads-cmp .mol-ads-non-tcf-vendors li:first-child {
    border-top: solid 1px #e3e3e3;
  }

  .mol-ads-cmp .mol-ads-non-tcf-vendors li {
    border-bottom: solid 1px #e3e3e3;
  }

  .mol-ads-cmp .mol-ads-non-tcf-item {
    width: 100%;
    padding: 12px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
  }

  .mol-ads-cmp .mol-ads-non-tcf-item a {
    margin-right: 32px;
  }

  .mol-ads-cmp .mol-ads-cmp-accordion {
    width: 100%;
  }

  .mol-ads-cmp .mol-ads-cmp-accordion.active .mol-ads-cmp-accordion-chevron {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .mol-ads-cmp .mol-ads-cmp-accordion.active .mol-ads-cmp-accordion-panel {
    display: block;
  }

  .mol-ads-cmp .mol-ads-cmp-accordion-title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 12px 0;
    cursor: pointer;
  }

  .mol-ads-cmp .mol-ads-cmp-accordion-title:hover {
    background-color: rgba(0, 0, 0, 0.10196);
  }

  .mol-ads-cmp .mol-ads-cmp-accordion-title-container {
    width: calc(100% - 20px);
  }

  .mol-ads-cmp .mol-ads-cmp-accordion-title-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-consent-bullet {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .mol-ads-cmp .mol-ads-cmp-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #00aad2;
    color: white;
    font-size: 14px;
    margin-right: 10px;
    text-align: center;
    padding: 3.5px;
  }

  .mol-ads-cmp .mol-ads-cmp-accordion-chevron {
    float: right;
    font-size: 14px;
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    transition: 0.4s;
    color: #828282;
  }

  .mol-ads-cmp .mol-ads-cmp-accordion-panel {
    display: none;
    overflow: hidden;
    width: 100%;
    transition: max-height 0.4s ease-out;
    margin: 10px 0;
  }

  .mol-ads-cmp .mol-ads-policy-link-list a {
    display: inline-block;
    text-decoration: underline;
    margin: 5px;
  }

  .mol-ads-cmp .mol-ads-cmp-list {
    margin-bottom: 10px;
  }

  .mol-ads-cmp .mol-ads-cmp-list-header {
    font-weight: bold;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-consent::after {
    clear: both;
    content: '';
    display: table;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-consent-policy {
    margin: 0 13px 0 7.7px;
    color: #003580;
    white-space: nowrap;
  }

  .mol-ads-cmp .mol-ads-cmp--settings .mol-ads-cmp--btn-primary,
  .mol-ads-cmp .mol-ads-cmp--settings .mol-ads-cmp--btn-secondary {
    margin-left: 1em;
  }

  .mol-ads-cmp .mol-ads-cmp--settings .mol-ads-cmp--btn-primary:hover {
    background: #097c09;
  }

  .mol-ads-cmp .mol-ads-cmp--settings .mol-ads-cmp--btn-secondary:hover {
    background: rgba(0, 0, 0, 0.10196);
  }

  .mol-ads-cmp .mol-ads-cmp--settings .mol-ads-cmp--modal-footer .mol-ads-cmp--switch-tabs {
    display: block;
    margin-bottom: 1em;
  }

  .mol-ads-cmp--switch-tabs .mol-ads-cmp--icon::before {
    content: 'â€º';
    font-size: 1.4em;
    line-height: 0.3em;
    padding-left: 0.3em;
  }

  .mol-ads-cmp .mol-ads-cmp--settings-advertising .mol-ads-cmp--modal-footer .mol-ads-cmp--switch-tabs,
  .mol-ads-cmp .mol-ads-cmp--settings-functional .mol-ads-cmp--modal-footer .mol-ads-cmp--btn-secondary {
    display: none;
  }

  .mol-ads-cmp .mol-ads-cmp-footer-privacy {
    float: left;
    color: #8d8d8d;
    font-size: 14px;
    margin-top: 12px;
  }

  .mol-ads-cmp .mol-ads-cmp-footer-privacy:hover {
    color: #595959;
  }

  .mol-ads-cmp .mol-ads-cmp-footer-buttons {
    float: right;
  }

  .mol-ads-cmp .mol-ads-cmp--settings .mol-ads-cmp--btn-primary,
  .mol-ads-cmp .mol-ads-cmp--settings .mol-ads-cmp--btn-secondary {
    height: 40px;
    width: 240px;
  }

  .ccpa .mol-ads-cmp .mol-ads-cmp-footer-privacy {
    text-decoration: underline;
  }

  .mol-ads-cmp .mol-ads-cmp--tabs {
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 3em;
    height: var(--mol-ads-cmp--height);
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  .mol-ads-cmp .mol-ads-cmp--tab {
    background-color: #f5f5f5;
    width: 50%;
  }

  .mol-ads-cmp .mol-ads-cmp--tab:hover {
    background-color: #c2c2c2;
  }

  .mol-ads-cmp .mol-ads-cmp--modal-body .mol-ads-cmp--tabs-container {
    border: solid 1px #e3e3e3;
    border: var(--mol-ads-cmp--border);
    margin-bottom: 2em;
  }

  .mol-ads-cmp .mol-ads-cmp--tab-trigger {
    border: solid 1px #e3e3e3;
    border: var(--mol-ads-cmp--border);
    border-top: none;
    color: black;
    display: block;
    font-weight: bold;
    height: 100%;
    line-height: 3em;
    line-height: var(--mol-ads-cmp--height);
    text-align: center;
    width: calc(100% - 2px);
  }

  .mol-ads-cmp .mol-ads-cmp--tab:first-child .mol-ads-cmp--tab-trigger {
    border-left: none;
    border-right: none;
  }

  .mol-ads-cmp .mol-ads-cmp--tab:nth-child(2) .mol-ads-cmp--tab-trigger {
    border-right: none;
  }

  .mol-ads-cmp .mol-ads-cmp--tab.active {
    background-color: transparent;
  }

  .mol-ads-cmp .mol-ads-cmp--tab.active .mol-ads-cmp--tab-trigger {
    border-bottom: none;
  }

  .mol-ads-cmp .mol-ads-cmp--tab-body {
    /* border-bottom: var(--mol-ads-cmp--border);
    border-left: var(--mol-ads-cmp--border);
    border-right: var(--mol-ads-cmp--border); */
    display: none;
    /* margin-bottom: 2em; */
    max-height: 300px;
    overflow: auto;
    padding: 1em;
  }

  .mol-ads-cmp .mol-ads-cmp--tab-body.active {
    display: block;
  }

  .mol-ads-cmp .mol-ads-cmp--alert {
    animation: alert 0.5s 1;
    animation-delay: 10s;
    animation-fill-mode: forwards;
    background-color: #004db3;
    border: solid 2px #b2c9e8;
    bottom: 3em;
    left: 5%;
    position: fixed;
    width: 90%;
    z-index: 100000;
  }

  .mol-ads-cmp .mol-ads-cmp--alert-text {
    background-color: rgba(255, 255, 255, 0.9);
    margin: 0;
    padding: 1em;
  }

  @keyframes :global(alert) {
    0% {
      opacity: 1;
    }

    95% {
      height: auto;
      opacity: 0;
      width: 90%;
    }

    100% {
      height: 0;
      opacity: 0;
      overflow: hidden;
      width: 0;
    }
  }

  .mol-ads-cmp .mol-ads-cmp--close {
    background-color: #000;
    border-radius: 50%;
    color: #fff;
    font-size: 1.7em;
    line-height: 0.7em;
    padding: 0.06em 0.1em 0.17em 0.13em;
    position: absolute;
    right: -0.5em;
    top: -0.5em;
  }

  @media (max-width: 600px) {
    /* layer 1 */
    .ccpa .mol-ads-cmp {
      pointer-events: none;
    }
    .ccpa .mol-ads-cmp .mol-ads-cmp--banner {
      pointer-events: all;
    }
    .ccpa .mol-ads-cmp .mol-ads-cmp--banner .mol-ads-cmp--buttons {
      -ms-flex-pack: center;
      justify-content: center;
      padding-top: 8px;
    }
    .ccpa .mol-ads-cmp .mol-ads-cmp--banner .mol-ads-cmp--message,
    .mol-ads-ccpa--message {
      height: 25px;
    }

    .ccpa .mol-ads-cmp .mol-ads-cmp--button-privacy-link-group {
      height: unset;
    }

    .ccpa .mol-ads-cmp .mol-ads-cmp--banner .mol-ads-ccpa--message {
      height: 20px;
      padding-top: 5px;
      margin: 0;
    }
    .mol-ads-cmp .mol-ads-cmp--modal-body .mol-ads-cmp--modal-message {
      height: calc(100% - 25px);
      overflow-y: auto;
      margin: 0;
    }
    .mol-ads-cmp .mol-ads-cmp--banner {
      padding: 11px 15px;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
    }
    .mol-ads-cmp .mol-ads-cmp--container {
      width: 100%;
      margin: 0;
    }
    .mol-ads-cmp .mol-ads-cmp--container h2 {
      margin: 0;
      font-size: 12px;
    }
    .mol-ads-cmp ul.mol-ads-cmp--publisher-purposes li {
      font-size: 11px;
      margin: 7px 0;
    }
    .mol-ads-cmp .mol-ads-cmp--button-privacy-link-group {
      display: block;
    }
    .mol-ads-cmp .mol-ads-cmp--message {
      font-size: 11px;
    }
    .mol-ads-cmp .mol-ads-cmp--buttons {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .mol-ads-cmp .mol-ads-cmp--buttons button {
      min-width: 8em;
      height: 43px;
      margin: 0;
    }
    .mol-ads-cmp .mol-ads-cmp--buttons button:first-child {
      margin-right: 15px;
    }
    .mol-ads-cmp .mol-ads-cmp--banner .mol-ads-cmp--btn-primary {
      min-width: 8em;
      height: 43px;
      margin: 0;
    }
    /* layer 2 */
    .mol-ads-cmp .mol-ads-cmp--modal {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
    }
    .mol-ads-cmp .mol-ads-cmp--modal-dialog-centered {
      height: auto;
    }

    .mol-ads-cmp .mol-ads-cmp--modal-content {
      height: calc(100% - 24px);
    }
    .mol-ads-cmp .mol-ads-cmp--modal-body {
      height: calc(100% - 185px);
      margin: 0;
    }
    .mol-ads-cmp .mol-ads-cmp--modal-body .mol-ads-cmp--tabs-container {
      height: 100%;
    }
    .mol-ads-cmp .mol-ads-cmp--tab-body {
      max-height: calc(100% - 39px);
      margin: 0;
    }
    .mol-ads-cmp .mol-ads-cmp-circle {
      width: 22px;
      height: 22px;
    }
    .mol-ads-cmp .mol-ads-cmp--modal-footer {
      height: 155px;
    }
    .mol-ads-cmp .mol-ads-cmp-footer-privacy {
      width: 100%;
      margin: 8px 0 15px;
    }
    .ccpa .mol-ads-cmp .mol-ads-cmp-footer-buttons {
      float: none;
      margin-top: 20px;
    }
    .ccpa .mol-ads-cmp .mol-ads-cmp-footer-privacy {
      margin: 0px 0 20px;
    }
    .ccpa .mol-ads-cmp .mol-ads-cmp--settings .mol-ads-cmp--btn-primary {
      margin: 0;
    }

    /* layer 3 */
    .mol-ads-cmp .mol-ads-cmp--settings .mol-ads-cmp--btn-primary,
    .mol-ads-cmp .mol-ads-cmp--settings .mol-ads-cmp--btn-secondary {
      margin-left: 0;
    }
  }

  @media (min-width: 880px) {
    .mol-ads-cmp {
      font-size: 16px;
      z-index: 99999;
    }

    .ccpa .mol-ads-cmp {
      pointer-events: none;
    }

    .mol-ads-cmp.mol-ads-cmp--settings-open,
    .mol-ads-cmp .mol-ads-cmp--container {
      pointer-events: all;
    }

    .mol-ads-cmp .mol-ads-cmp--container {
      width: 844px;
    }

    .mol-ads-cmp .mol-ads-cmp--banner .mol-ads-cmp--btn-primary {
      float: right;
    }

    .mol-ads-cmp .mol-ads-cmp--modal-dialog {
      height: auto;
      top: 3em;
      width: 847px;
    }

    .mol-ads-cmp .mol-ads-cmp--modal-body .mol-ads-cmp--modal-message {
      height: 250px;
      overflow-y: auto;
      margin-bottom: 25px;
      border: 2px solid #e3e3e3;
      padding: 5px;
    }

    .mol-ads-cmp .mol-ads-cmp--modal-content {
      width: 83.5%;
    }

    .mol-ads-cmp .mol-ads-cmp--settings-functional .mol-ads-cmp--modal-footer {
      -ms-flex-line-pack: center;
      align-content: center;
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: column-reverse;
      flex-flow: column-reverse;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 1em;
    }

    .mol-ads-cmp .mol-ads-cmp--alert {
      left: 50%;
      margin-left: -30em;
      position: fixed;
      width: 60em;
    }

    .mol-ads-cmp--tooltipframe {
      min-height: auto;
      margin-bottom: 27px !important;
      overflow-y: visible;
    }

    .mol-ads-cmp .mol-ads-cmp--tooltipcontent:hover,
    .mol-ads-cmp--tooltip-trigger:hover ~ .mol-ads-cmp--tooltipcontent {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    @media (min-height: 600px) {
      /* Triangles */
      .mol-ads-cmp .mol-ads-cmp--tooltipcontent:target::before,
      .mol-ads-cmp--tooltip-trigger:hover ~ .mol-ads-cmp--tooltipcontent::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -13px;
        margin-left: -0.6em;
        border-width: 1em 0.6em;
        border-style: solid;
        border-color: rgba(153, 153, 153, 1) transparent transparent transparent;
        z-index: 0;
      }

      .mol-ads-cmp .mol-ads-cmp--tooltipcontent:target::after,
      .mol-ads-cmp--tooltip-trigger:hover ~ .mol-ads-cmp--tooltipcontent::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -11px;
        margin-left: -0.6em;
        border-width: 1em 0.6em;
        border-style: solid;
        border-color: rgba(244, 249, 255, 1) transparent transparent transparent;
        z-index: 2;
      }
    }

    .mol-ads-cmp .mol-ads-cmp--tooltipcontent:not(:target) .mol-ads-cmp--tooltip-close {
      display: none;
    }
  }

  /* Hover tooltip workaround */
  @media (max-width: 879px) {
    .mol-ads-cmp .mol-ads-cmp--info {
      width: 12px;
      height: 12px;
    }

    .mol-ads-cmp--tooltip.triggeredtooltip {
      .mol-ads-cmp--tooltipcontent {
        display: block;

        .mol-ads-cmp--tooltip-close {
          display: block !important;
        }
      }
    }
  }
}
